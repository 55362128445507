
.reportHeader{
    width: 100%;
    position: relative;
    height: 67vw;
    max-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.reportHeader-img{
    position: absolute;
    /* z-index: -10; */
    width: 100%;
   height: 110%;
}
.reportHeader-heading{
    z-index: 10;
    color: white;
   
}
.reportHeader-heading h2{
    font-weight: 700;
    font-size: clamp(30px,5vw,53px);
    text-shadow: 2px 2px 10px black;
}
.reportContent{
    width: 100%;
    
    margin-top: 30px;
    margin-bottom: 30px;
    
   
    
    padding-left:  clamp(20px,10%,200px);;
}
.reportContent-links{
    border: 2px solid black;
display: inline-block;
    background-color: rgb(237, 241, 240);
    padding: 20px;

}
.reportContent-links-single{

margin-top: 10px;
}
.reportContent-links-single a{
    color: grey;
    text-decoration: none;
}