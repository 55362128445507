.Programs-HomePage{
    /* height: 800px; */
    /* background-color: rgb(2, 164, 145); */
    /* background-image: url("../../public/images/programs/nutrition/nutrition1.JPG");
   background-size: 100% 100%; */
  
    /* margin-top: 5vh; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    background-color: rgb(255, 255, 255);
    height: auto;
     
}
.Programs-HomePage-backgroundimg{
position: absolute;
width: 100%;
height: 100%;
}
.Programs-HomePage-heading{
width: 100%;
display: flex;
justify-content: center;
z-index: 1;
margin-top: 20px;
}
.Programs-HomePage-heading h1{
   font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    /* display: inline-block; */
    font-size: 30px;
/* padding: 15px;
    text-align: center;
    margin-top: 5vh;
    font-size: clamp(20px,2em+10vw,60px); */
    /* font-weight: 10; */
    /* font-weight: 700;
 
    border: 3px solid white;
    background-color: rgb(17, 5, 66);
    color: rgb(133, 204, 251);
    border-radius: 25px; */
    /* border-top: 2px solid rgb(221, 161, 21);
    border-bottom:  2px solid rgb(221, 161, 21); */
}

.programType{
    /* width: 300px; */
    overflow: hidden;
    width: 300px;
    min-width: 200px;
    height: 350px;
    /* background-color: rgb(65, 121, 34); */
    margin: 2vw;
    transition: border 200ms ease;
    position: relative;
    /* border: 8px solid rgb(218, 203, 203); */
}
.programType:nth-child(odd){
    margin-top: 6vw;
    /* margin-top: calc(); */
   background-color: bisque;
}
.programType:hover {
    /* box-shadow: 0 4px 1rem -4px #000; */
    /* transform: translateY(-3px); */
    border: 10px solid rgb(121, 122, 121) ;
  }
 
 .programType-tagline {
  
position: absolute;
top:50%;
left:50%;
transform: translate(-50%,-50%);
text-align: center;
width: 80%;
margin: auto;
transition:  opacity 1500ms  ease ;
 }
 
 .programType-tagline p{
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-shadow: 1px 1px 1px rgb(8, 8, 8);
    color: rgb(181, 222, 181);
 }
 .programType-top-heading {
    position: absolute;
    bottom: 0;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    transition: opacity 300ms ease;

    
 }
  .programType-top-heading h4{
   
    color: rgb(255, 255, 255);

    font-size: 22px;
    text-align: center;
    
    text-shadow: 2px 2px 10px rgb(0, 0, 0);
  }
  .programType img{
    position: absolute;
    left:-6vw;
    height: 100%;
    width: 150%;
  }
  .programType-bottom-heading{
    transition: opacity 800ms ease;
    position: absolute;
    background-color: rgba(157, 156, 155, 0.795);
  bottom: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
 /* height: 20%; */
    text-transform: capitalize;
    color: rgb(104, 46, 9);
    align-items: center;
  }
  .programType-bottom-heading h4{
    font-size: 25px;
    text-align: center;
   font-weight: 700;
  }


