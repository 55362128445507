/* green - #6cb68e 
violet - #9292c7 
blue - #12498f */
*{
  box-sizing: border-box;
}
.footer{
padding-left: calc( 12% - 80px ) ;
padding-right: calc( 12% - 80px ) ;
/* background-color: #6cb68e ; */
background-color: #9292c7 ;
/* background-color: #12498f; */

padding-top: 10px;
padding-bottom: 10px;
display: flex;

flex-direction: column;
flex-wrap: wrap;
justify-content: center;
align-items: center;
width: 100%;
}
.footerDisclaimer{
  
  width: 90%;
  margin: auto;
  /* border:2px solid grey; */
}
.footerDisclaimer p{
  margin: 0;
  padding: 0;
}
.footer-addressNconnect{

  width: 100%;
  margin: auto;
  display: flex;
  flex-basis: 50%;
  flex-wrap: wrap;
  /* background-color: rgb(47, 77, 6); */
justify-content: center;
align-items: center;
}
.footerAddress{
  flex-basis: 49%;

  /* flex: 1; */
  /* margin-right: clamp(2vw,100px); */
  /* background-color: rgb(151, 91, 14); */
  min-width: 250px;
  padding: 20px 10px;
  margin-right: clamp(13px,1.74vw,1000px);

}
.footerAddress span{
  color: white;
}
.footerConnect{
  flex: 1;
  flex-basis: 49%;
  /* height: 100%; */
  /* height: initial; */
  /* background-color: antiquewhite; */
  /* margin-top: 20px;
  margin-bottom: 20px; */
  padding-top: 20px;
  padding-bottom: 20px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  /* border-left: 2px solid rgb(80, 140, 153);
   border-right: 2px solid rgb(80, 140, 153); */
/* width: clamp(250px,90vw,400px); */
width: 100%;
max-width: 500px;
/* width: 250px; */
/* flex-basis: 100%; */

 /* background-color: rgb(184, 224, 224);  */
}

.footerConnect h4{
  font-weight: 700;
  text-align: center;
 
  /* flex-basis: 100%; */
  color: #43437b ;

}
.socialHandles{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  /* flex-basis: 100%; */
  max-width: 400px;
  
  }
.socialHandles-single-icon{
   /* background-color: aqua;  */
   
   flex-basis: 25%;
 height: 50px;
 display: flex;
 justify-content: center;

align-items: center;
}
.socialHandles span{
  font-size: 30px;
  /* background-color: blue;  */
  transition: all 300ms ease;

}
.socialHandles span:hover{
   font-size: 36px; 
  padding-bottom: 10px
}
.socialHandles a{

  color: #43437b ;
 
}

.footerDisclaimer h5{
   font-weight: 700; 
  color: rgb(251, 251, 251); 
}
.footerDisclaimer p{
  text-align: justify;
  color: rgb(208, 206, 206);
}

.footerNavigate{

  padding-left: 10px;
 color: white;
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
 align-items: center;
 max-width: 1000px;
 /* padding: 5px; */
}
.footerNavigate span{
margin-left: 5px;
margin-right: 5px;
cursor: pointer;
transition: all 500ms ease;
}
.footerNavigate a{
  text-decoration: none;
  color: #43437b;
}
.footerNavigate span:hover{
 color: rgb(255, 255, 255);
  }
.footerNavigate h6{
  padding-top: 8px;
  margin-right: 5px;
}
.footerCopyRight p{
  color: #43437b ;
}
@media screen and (max-width: 550px) {
  .footer{
    padding-left: 10px;
padding-right: 10px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
  }
  .footerAddress {
    display: flex;
    flex-direction: column;
   
  }

}
