.loader{
    z-index: 100000000;
    width: 100%;
    height: 100vh;
    background-color: rgba(118, 198, 96, 0.632);
    position: fixed;
    top:0;
    left:0;
}
.loader-spinner{
position: absolute;
width: 180px;
height: 180px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 100px;
background-color: rgb(253, 253, 251);
top:50%;
left:50%;
transform: translate(-50%,-50%);
}
.loader-logo{
position: absolute;
top:50%;
left:50%;
transform: translate(-50%,-50%);
width: 80px;
height: 80px;

}
.loader-logo img{
width: 100%;
height: 100%;
}
.spinner{
    background-color: rgb(89, 174, 146);
    width: 100%;
    height: 100%;
}
