.educationHighlights_heading{
   height: 40vh;
background-color: rgb(5, 35, 50);
display: flex;
justify-content: center;
align-items: center;
text-align: center;
color: rgb(255, 255, 255);
padding-top:10vh;
background-size: 100%;
backdrop-filter: blur(100px);
filter: grayscale(90%);
}



/* .educationHighlights_heading h3{

} */
.educationHighlights_heading h3{
   z-index: 100;
   background-color: rgba(0, 0, 0, 0.307);

}
.educationHighlights-content{
    width: 80%;
    margin: auto;
    padding-top: 8vh;
    /* margin-left: clamp(0px,14vw,200px); */
}
.educationHighlights-content h3{
    color: rgb(210, 83, 33);
}
.educationHighlights-images{
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgb(110, 106, 106);
}
.educationHighlights-images img{
    margin: 10px;
    width: 400px;
    min-width: 230px;
    max-width: 800px;
   
}
.educationHighlights-desc{
    max-width: 900px;
}
/* .educationHighlights-desc p{
    font-size: 18px;
} */