.new_component{
    width: 100%;
}
.new_component div{
    width: 400px;
    height: 400px;
    background-color: black;
    color: white;
}
.new_component img{
    width: 600px;
}
