.commonReadmoreHeader{
    position: relative;
    width: 100%;
    height: 250px;
    background-color: black;
   
    /* background-size: 100% 400%;
    background-position: -10% -50% ; */
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow:hidden;
}
.commonReadmoreHeader img {
    width: 100%;
    position: absolute;
    top:-50%;
}
.commonReadmoreHeader-internalDiv{
    z-index: 10;;
}
.commonReadmoreHeader-internalDiv h2{
    text-shadow: 2px 2px 5px black;
}
.commonReadmoreContent{
    width: 100%;
    background-color: rgb(227, 230, 229);
    margin: auto;
    padding: 6vw 10vw;
 /* margin-top: 50px; */
 /* max-width: 1100px; */

}
.commonReadmoreContent img{
    width: 100%;
    max-width: 800px;
    margin-top:5px;
/* border: 2px solid red; */
}
.commonReadmoreContent-heading p{
    text-align: justify;
}

