.impactStoriesDetailHeader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    height: 300px;
}
.impactStoriesDetailHeader-img{
    width: 100%;
    position: absolute;
    z-index: -10;
}
.impactStoriesDetailHeader-heading{

    z-index: 10;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 80px;
    background-color: rgba(0, 0, 0, 0.619);
}
.impactStoriesDetailHeader-heading h2{
    color: white;
    padding: 0;
    margin: 0;
}
.impactStoriesDetailContent{
    margin-top: 70px;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}
.impactStoriesDetailContent h3{
text-transform: uppercase;
color: rgb(3, 3, 92);
font-weight: 700;
}
.impactStoriesDetailContent h5{
    text-transform: capitalize;  
    color: rgb(78, 76, 76);
}
.impactStoriesDetailContent img{
    width: 70vw;
    max-width: 900px;
}
.impactStoriesDetailContent p{
    font-size: 18px;
    margin-top: 30px;
    max-width: 1000px;
    color: rgb(82, 84, 83);
}