
.aboutUsHeader{
    width: 100%;
    height: clamp(300px,45vw,830px);
    position: relative;
    
}

.aboutHeader{
    height: clamp(300px,80vw,720px);
    /* background-color: aqua; */
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.aboutHeader-content{
    z-index: 1000;
}
.aboutHeader-content h1{
    font-size: clamp(30px,10vw,70px);
    text-shadow: 2px 2px black;
    padding: 0;
    margin: 0;
    color: white;
}

.Cover-AboutPage{
    position: absolute;
    height: 100%;
    width: 100%;
}
.aboutUsHeader-heading{
   z-index: 10;
    position: absolute;
}
.aboutUsHeading{
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}



.aboutUsHeading h3{
    margin-top: 30px;
    color: rgb(3, 3, 128);
    font-weight: 700;
    font-size: clamp(14px,4vw,100px);
}
.aboutUsHeading img{
    width: 150px;
}
.aboutUsHeading p{
    text-align: justify;
   width: 90%;
   max-width: 1100px;
   background-color: rgb(242, 244, 247);
   padding: 10px;
}
.aboutUsApproach{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.aboutUsApproach h3{
    margin-top: 30px;
    color: rgb(34, 34, 167);
    font-weight: 700;
    font-size: clamp(14px,3.6vw,100px);
}
.aboutUsApproach p{
    text-align: justify;
    width: 90%;
    max-width: 1100px;
    background-color: rgb(242, 244, 247);
    padding: 10px;
}
.aboutUsBoardMembers{
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.aboutUsBoardMembers-heading h3{
    
    color: rgb(34, 34, 167);
    font-weight: 700;
    font-size: clamp(14px,3.6vw,100px);
}
.aboutUsBoardMembers-content{
width: 100%;

background-color: rgb(231, 237, 237);
padding: 10px;
display: flex;
flex-direction: column;
}
.aboutUsBoardMembers-content-chairman{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: rgb(142, 3, 75);
}

.aboutUsBoardMembers-content-chairman-img{
    flex-basis: 50%;
    height: auto;
    /* border: 2px solid red; */

}
.aboutUsBoardMembers-content-chairman-img img{
    width: 100%;
}
.aboutUsBoardMembers-content-chairman-content{
    padding: 20px;
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: rgb(110, 9, 61);
    height: auto;
}
.aboutUsBoardMembers-content-chairman-content h2{
color: white;
text-align: center;
}
.aboutUsBoardMembers-content-chairman-content h3{
    color: white;
    text-align: center;
}
.aboutUsBoardMembers-content-chairman-content p{
    text-align: center;
color: rgb(242, 195, 218);
}


.aboutUsBoardMembers-registeredUnder{
    width: 90%;
    max-width: 1100px;
}
.aboutUsBoardMembers-registeredUnder p{
    background-color: rgb(236, 243, 241);
    padding: 10px;
}
.aboutUsBoardMembers-content-others{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
  
    background-color: rgb(215, 218, 218);
    
}
.aboutUsBoardMembers-content-others-single{
    width: clamp(250px,90vw,400px);
    margin: 30px 10px;
    background-color: rgb(252, 252, 251);
    border-radius: 20px;
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
}
.aboutUsBoardMembers-content-others-single-img-container{
    width: 100%;
    height: clamp(200px,60vw,300px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.aboutUsBoardMembers-content-others-single-img-container img{
   height: 100%;
}
.aboutUsBoardMembers-content-others-single h4{
   text-align: center;
    margin: auto;

}
.aboutUsBoardMembers-content-others-single h3{
    margin: auto;
    background-color: rgb(127, 4, 4); 
    width: 100%;
    text-align: center;
    color: white;
}
.aboutUsBoardMembers-content-others-single p{
    margin: 20px
}
.modal-body-addChairman form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* background-color: aqua; */
    /* justify-content: first start; */
    padding: 10px;
}
.modal-body-addChairman form input{
    margin-top: 20px;
    width: 90%;
}
.modal-body-addChairman form textarea{
    margin-top: 20px;
    width: 90%;
}
/* .modal-body-addChairman form label{
    margin: 10px;
    width: 90%;
} */
.modal-body-addChairman form button{
    width: 200px;
    margin-top: 10px;
    margin: auto;
}
.modal-body-addBoardMemberModal form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* background-color: aqua; */
    /* justify-content: first start; */
    padding: 10px;
}
.modal-body-addBoardMemberModal form input{
    margin: 10px;
    width: 90%;
}
.modal-body-addBoardMemberModal form textarea{
    margin: 10px;
    width: 90%;
}
.modal-body-addBoardMemberModal form label{
    margin: 10px;
    width: 90%;
}
.aboutUsBoardMembers-content button{
    margin: auto;
 padding: 3px 10px ;
 margin: 5px;
 border: none;
 background-color: rgb(130, 4, 4);
 color:white;
 width: 200px;
align-self: center;
}

@media screen and (max-width: 850px) {
    .aboutUsBoardMembers-content-chairman{
        flex-direction: column;
        align-items: center;
    }
}
/*

.aboutUsPic{
width: 100vw;
z-index: -10;
position: fixed;
top:0;
opacity: 0.8;

}
.aboutUs h1{
    font-weight: 60;
    font-size: 100px;
    color: white;
    display: block;
    text-align: center;
    position: relative;
    top:50vh;
    text-shadow: 2px 2px #000000;
}
.contentAboutUs{
   
   margin-top: 50vw;
   background-color: rgb(248, 241, 241);
   background-color: rgb(255, 255, 255);
padding-top: 10vh;
padding-bottom: 10vh;
}
.logoMain{
width: 200px;
height: 200px;
display: block;
  margin-left: auto;
  margin-right: auto;

}
.ourApproach{
    border-radius: 5px;
    margin:5vh auto 5vh auto;
    
    padding:40px 10vw 80px 10vw ;
    background-color: rgb(193, 218, 218);
   
}
.ourApproach h3{
display:block;
text-align: center;
font-weight: 600;
font-size: 40px;

}
.ourApproach span{
    text-align: justify;
    width: 80vw;
}
.cmf{
    border-radius: 5px;
    margin:10vh auto 10vh auto;
    width: 80vw;

  
}
.cmf h3{
display:block;
text-align: center;
font-weight: 700;
font-size: 50px;
margin-bottom: 5vh;
color: rgb(65, 65, 145);
}
.cmf span{
  
    justify-content: space-between;
    text-align: justify;
    display: flex;
}
.boardMembers{
    border-radius: 5px;
    margin:5vh auto 5vh auto;
    width: 80vw;
   margin-top: 10vh;
   
}
.boardMembers h3{
display:block;
text-align: center;
font-weight: 800;
font-family: Verdana, Geneva, Tahoma, sans-serif;

}
.boardMembers span{
    text-align: justify;
}

.each-member{
    background-color: rgb(228, 239, 236);
    margin: 20px;
   display: flex;
   justify-content: space-between;
    border-radius: 30px;
    width: 80vw;
    margin: 50px auto 50px auto;
    padding: 3vw;
    box-shadow: 5px 5px 5px rgb(215, 205, 205);
    
}
.descrip{
 
    flex-basis: 70%;
    justify-content: space-between;
  
    text-align: justify;
    display: flex;
   
    border-radius: 30px;
    margin: 5px;
    

}
.boardMemPic{
    flex-basis: 30%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.boardMemPic img{
    margin:auto 20px auto 20px;
    width: 280px;
    height: 280px;
    border-radius: 140px;
}
.boardMemPic span{
    color: rgb(19, 19, 92);
    font-weight: 700;
    font-size: 1.2rem;
    display: block;
    align-items: center;
    justify-content: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
}


@media screen and (max-width: 950px) {
    .each-member{
        flex-direction: column;
        align-items: center;
    }
    
   
  } */

  