.socialMedia{
    background-color: rgb(2, 63, 63); 
padding-bottom: 20px;
}
.socialMedia-checkout{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    animation: blip 500ms linear infinite;
}
@keyframes blip{
    0%   { color: rgb(232, 65, 10); }
    100%   { color: rgb(243, 223, 7); }
}
.socialMedia-checkout h4{
    /* color: white; */
    font-size: clamp(16px,3vw,40px);
}
.socialMedia-content{
   
    display: flex;
  
    justify-content: space-around;
}
/*------------------------------FACEBOOK----------------------------*/

.socialMedia-facebook{
    /* overflow: hidden; */
    flex-basis: 50%;
    height: 550px;
    background-color: rgb(225, 225, 219);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.socialMedia-facebook-heading{
    width: 100%;
    max-width: 450px;
    display: flex;
    background-color: rgb(4, 28, 108);
    border-radius: 20px 20px 0px 0px;
    height: 100px;
    justify-content: center;
    align-items: center;
}
.socialMedia-facebook-container{
    /* background-color: rgb(192, 112, 15); */
    width: 100%;
    max-width: 450px;
    height: 100%;
    overflow: hidden;
}
.socialMedia-facebook h3{
    text-shadow: 2px 2px 10px black;
    font-weight: 700;
   font-size: 35px;
color: aliceblue;
}

/*----------------------TWITTER-------------------------------*/

.socialMedia-twitter{
    background-color: rgb(223, 223, 226);
    flex-basis: 50%;
    height: 550px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.socialMedia-twitter-container{
  background-color: bisque;
  width: 100%;
  max-width: 450px;
  height: 100%;
  overflow: hidden;
}
.socialMedia-twitter-heading{
    width: 100%;
    max-width: 450px;
    display: flex;
    background-color: rgb(20, 63, 204);
    border-radius: 20px 20px 0px 0px;
    height: 100px;
    justify-content: center;
    align-items: center;
}
.socialMedia-twitter-heading h3{
    text-shadow: 2px 2px 10px black;
    font-weight: 700;
   font-size: 35px;
color: aliceblue;
}
/* .TwitterTimelineEmbed{
width: 700px;
} */

@media screen and (max-width: 800px) {
    .socialMedia-content{
        display: flex;
        flex-direction: column;
        height:auto ;
        /* height: 1700px; */
     
    }



    .socialMedia-facebook{
        width: 90%;
        margin: auto;
        height: 550px;
        flex-basis: auto;
    }
    .socialMedia-twitter{
        width: 90%;
        margin: auto;
        height: 550px;
        flex-basis: auto;
    }
    /* .socialMedia-facebook-container{
        width: 90%;
        min-width: 250px;
        height: 100%;
    } */
}