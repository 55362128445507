.contactUs{
    overflow: hidden;
}

.contactUsHeader{
position: relative;
width: 100%;
height: 300px;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;

}
.contactUsHeader-img{
   width: 100%;
   position: absolute;
   top:0;
   left:0;
   height: 300px;


}
.contactUsHeader-img img{
position: absolute;
  bottom: -250px;
  left: 0;
width: 100%;
height:240%;
/* height: 100%; */
}
.contactUsHeader-heading{
    z-index: 100;
color: white;
width: 100%;
height: 100%;
display: flex;
justify-content: flex-start;
align-items: flex-end;

border-bottom: 1px solid rgb(106, 2, 2);
}
.contactUsHeader-heading h1{
 
    font-weight: 700;
    color: white;
    text-shadow: 2px 2px 10px black;
    font-size: clamp(30px,6vw,60px);
    /* text-shadow: 3px 3px 3px rgb(65, 110, 165); */
   
    margin: 20px;
   
}
.contactUsContent{
    width: 100%;
    
}
.contactUsContent{
    display: flex;
    flex-wrap: wrap;
}
.contactUsContent-left{
    /* width: 50%; */
   padding-top: 40px;
   padding-bottom: 40px;
   color: rgb(53, 52, 52);
    min-width: 350px;
    border-top: 5px solid rgb(16, 67, 98);
    flex:1;
    background-color: rgb(104, 91, 91);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
}

.contactUsContent-right{
    /* width: 50%; */
   
    width: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    flex:1;
    
    min-width: 350px;
    background-color: rgb(53, 52, 52);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-top: 5px solid rgb(16, 67, 98);
    justify-content: flex-start;
align-items: center;
   
}
.contactUsContent-right-form-container {
    width: 90%;
    margin: auto;
    margin-top: 30px;
}
.contactUsContent-right-form-container form{
    display: flex;
    flex-direction: column;
}
.contactUsContent-right-form-container form input{
    width: auto;
    margin:10px;
    background-color: rgb(53, 52, 52);
    font-size: 20px;
    height: 50px;
    border: none;
    border-left: 2px solid grey;
    border-bottom: 2px solid grey;
    border-radius: 10px;
    color: white;
}
.contactUsContent-right-form-container form textarea{
    width: auto;
    margin:10px;
    background-color: rgb(53, 52, 52);
    font-size: 20px;
     color: white;
    border: none;
    border-left: 2px solid grey;
    border-bottom: 2px solid grey;
    border-radius: 10px;
}
.contactUsContent-left-info{
    
   background-color: rgb(216, 214, 211);
    width: 70%;
    padding: 20px;
    margin-top: 30px;
    

}
.contactUsContent-right-form-container form button{
    width: 200px;
    margin: auto;
    padding: 5px;
    font-size: 20px;
    border: none;
    transition: all 400ms ease;
}
.contactUsContent-right-form-container form button:hover{
    width: 190px;
    padding: 10px;
    font-size: 18px;
    color: white;
    background-color: rgb(36, 35, 35);
}
.contactUsContent-left h5{
    background-color: rgb(53, 52, 52);
    color:rgb(223, 220, 220); 
    border-radius: 40px;
    margin: 0;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    text-transform: uppercase;
font-size: clamp(25px,4vw,40px);

}
.contactUsContent-left-info span{
font-weight: 600;
font-size: 18px;
}
.contactUsContent-left-info-odd{
    font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.contactUsContent-right h5{
    
   
    background-color: rgb(223, 220, 220);
    color: rgb(53, 52, 52);
    border-radius: 40px;
    margin: 0;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    text-transform: uppercase;
font-size: clamp(25px,4vw,40px);
justify-content: flex-start;
}

.contactUsContent-one{
    width: 100%;
    flex-basis: 100%;
    background-color: rgb(255, 255, 255);
    /* height: 400px; */
    display: flex;
    justify-content: space-evenly;
}
.contactUsContent-one-left{
    background-color: rgb(252, 253, 253);
    padding: 20px;
    display: flex;
    flex-basis: 40%;
    justify-content: center;
    align-items: center;
}
.contactUsContent-one-left p{
    text-align: justify;
}
.contactUsContent-one-right{
position: relative;
bottom: 30px;
background-color:rgb(255, 255, 255);
height:100% ;
flex-basis: 40%;
height: 110%;
/* border-left: 1px solid rgb(117, 2, 2); */
padding: 10px;
transition: all 800ms ease;
border-top: 1px solid rgb(170, 168, 168);
border-left: 1px solid rgb(177, 176, 176);
z-index: 100;
}
.contactUsContent-one-right:hover{
    box-shadow: 2px 2px 10px black;
}
.contactUsContent-one-right-internalDiv{
    width: 100%;
    height: 100%;
}
.contactUsContent-two{
    width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contactUsContent-two h5{
font-size: 25px;
}
.contactUsContent-two form{
    width: 100%;
    max-width: 800px;
    background-color: rgb(229, 227, 225);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.contactUsContent-two form input{
    border: none;
    padding-left: 10px;
    height: 50px;
    margin: 20px 2px;
    width: 90%;
    max-width: 600px;
    transition: all 1000ms ease;
}
.contactUsContent-two form input:hover{
    border: 1px solid black;
}
.contactUsContent-two form select{
    border: none;
    padding-left: 10px;
    height: 50px;
    margin: 20px 2px;
    width: 90%;
    max-width: 600px;
    transition: all 1000ms ease;
    padding-right: 100px;

}
.contactUsContent-two form textarea{
    border: none;
    padding-left: 10px;
    height: 150px;
    margin: 20px 2px;
    width: 90%;
    max-width: 800px;
    transition: all 1000ms ease;
}
.contactUsContent-two form button{
    align-self: center;
    width: 200px;
    padding: 3px 10px;
    text-align: center;
    background-color: rgb(147, 24, 0);
    border: none;
    color: white;
}
.contactUsContent-two form button:hover{
    box-shadow: 2px 2px 10px rgb(138, 137, 137);
}
@media screen and (max-width: 600px){
    .contactUsHeader{
        height: 200px;
        
    }
    .contactUsHeader-heading{
        align-items: flex-end;
       
    }
    .contactUsHeader-heading h1{
        top: 0;
    }
    .contactUsContent-one{
        flex-direction: column;

    }

    .contactUsContent-one-right{
        bottom: 0;
        height: auto;
    }
}

.modal-body-addVanacy form{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }
  .modal-body-addVanacy form input{
      margin-top: 10px;
      width: 100%;
      max-width: 700px;
  }
  .modal-body-addVanacy form textarea{
      margin-top: 10px;
      width: 100%;
      height: 100px;
      max-width: 700px;
  }
  .modal-body-addVanacy form button{
      width: 200px;
      margin: auto;
  }


/* @media screen and (max-width: 550px) {
    .contactUsHeader-img{
        width: 115%;
        left: -15%;
        top: 0;
        height: 400px;
        min-height: 0;
    }
    .contactUsHeader-heading{
        position: fixed;
        margin-left: 0;
       
      display: flex;
      justify-content: center;

        width: 100%;
        
    }
    .contactUsHeader-heading h1{
        text-shadow: 2px 2px 10px black;
        background-color: rgba(20, 74, 119, 0.807);
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        
    }
} */

.vanancyContent{
    background-color: rgb(228, 235, 235);
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
}
.post-vacancy-button{
    color: white;
    background-color: rgb(159, 8, 33);
    border: none;
    width: 200px;
    margin: auto;
    display: inline-block;
    padding: 5px 10px;
    margin: 10px auto;
}
.vanancyContent h3{
    font-weight: 700;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: rgb(4, 4, 101);

}
.vanancyContent-vacancies{
    background-color: rgb(187, 194, 194);
    width: 95%;
    margin: auto;
    max-width: 800px;
    padding: 10px;
}
.vanancyContent-vacancies-single{
    /* background-color: rgb(76, 128, 20); */
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    padding: 10px;
    background-color: rgb(224, 224, 224);
    margin: 10px;
  
}
.vanancyContent-vacancies-single h4{
text-transform: uppercase;
color: rgb(68, 67, 67);

}
.vanancyContent-vacancies-single p{
font-size: 17px;
text-align: justify;
}
.vanancyContent-vacancies-single-buttons{
display: flex;
justify-content: space-evenly;
}
.vanancyContent-vacancies-single-buttons button:nth-child(1){
background-color: rgb(46, 5, 157);
  margin-top: 10px;
  width: 200px;
  border: none;
  color: white;
  padding: 5px 10px;
}
.vanancyContent-vacancies-single-buttons button{
    background-color: rgb(157, 5, 48);
    margin-top: 10px;
    width: 200px;
    border: none;
    color: white;
    padding: 5px 10px;
    }
    .vanancyContent-vacancies-single-buttons a{
        text-decoration: none;
        color: white;
    }
    .vanancyContent-vacancies-novacancy{
        margin: auto;
        display: block;
      
        text-align: center;
    }
