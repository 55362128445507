
.washContainer{
    background-color: rgb(139, 137, 141);
 width: 100%;
 
  height: clamp(150px,60vw,700px);
  min-height: 150px;
  max-height: 800px;

 object-fit: cover;
 overflow: hidden;
 position: relative;
 }
 .wash-heading-container{
     /* background-color: #3360c2; */
     padding: 1vw;
     display: inline-block;
     position: absolute;
     bottom: 10%;
     left: 5%;
    z-index: 20;


 }
 .wash-heading-container h1{
     color: rgb(229, 137, 17);
     font-size:clamp(22px,6vw,50px);
     text-shadow: 2px 2px 1px rgb(0, 0, 0);
   
 }
 .wash-heading-container span{
    font-size:clamp(18px,3vw,30px);
    color: #dfd5d5;
    text-shadow: 2px 2px #070707;
}
 .washContainer-overlay{
    width: 100%;
     height: 100%;
     position: absolute;

     z-index: 10;
    background-color: #1c1b1b7a;
}




.washHeading{

    margin-top: 5vh;
    display: flex;
    flex-direction: column;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   width: 80%;
   margin: auto;
   /* margin-top: 5vh; */
  
}

.washHeading h2{
    
    color: rgb(0,78,126);
    font-weight: 700;
    font-size: clamp(30px,5vw,50px);
    border-bottom: 4px solid rgb(255, 140, 0);

}
.washHeading span{
    font-weight: 500;
    font-size: clamp(16px,1.2rem,25px);
    text-align: center;
}

.washAbout{

    width: 100%;
    max-width: 1100px;

    margin: auto;
    /* overflow: hidden; */
    /* background-color: #173473; */
    padding: 2px;
 
    min-height: 220px;
    max-height: 830px;
   
}
.washAbout-content{
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px;
z-index: 10;
height: 100%;
box-shadow: 0px 5px 10px rgb(99, 97, 97);
position: relative;
bottom:40px;


}
.washAbout-background-pic{
    position: absolute;
    width: 100%;
    height: 180%;
}
.washAbout-content p{
    font-family: "PT Serif Caption";
    color: #070707;
    text-align: justify;
}
.washAbout-content ul li{
    color: #dac1ae;
}

.washstrategy-new{
    width: 95%;
    max-width: 1200px;
    margin: auto;
    font-size: 25px ;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border: 3px solid red; */
}
.washstrategy-new h4{
    font-size: 40px;
    color: #173473;
    text-shadow: 1px 2px 1px #264c9f;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.washstrategy-new h6{
    text-align: center;
}
.washstrategy-content{
    /* border: 4px solid brown; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.washstrategy-content div{
    /* border: 4px solid rgb(220, 209, 209); */
  
    flex:1;
    flex-basis: 50%;
    max-width: 500px;
    width: 90%;
    min-width: 250px;
     padding: 20px 10px;
    display: flex;
margin: 10px;
  
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: rgb(8, 8, 8);
    /* transition: box-shadow 0.5s ease; */
    
}
.washstrategy-content div ul{
  /* background-color: #173473; */
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}
.washstrategy-content div ul li{
    font-size: 17px;
}
.washstrategy2-new{
   
    background-color: rgb(142, 189, 119);
   box-shadow: 5px 5px 5px rgb(21, 61, 1);
   
   
}
.washstrategy4-new{

    background-color: rgb(236, 171, 119);
    box-shadow: 5px 5px 5px rgb(116, 7, 47);
  
}
.washstrategy1-new{

    background-color: rgb(209, 194, 63);
    box-shadow: 5px 5px 5px rgb(121, 109, 2);
}
.washstrategy3-new{
    
    background-color: #9ab4ec;
    box-shadow: 5px 5px 5px #032d88;
}

.wash-journeySoFar{
    width: 95%;
    max-width: 900px;
    /* background-color: rgb(211, 210, 210); */
    color: rgb(79, 77, 77);
    padding: 20px;

    margin: auto;
    margin-top: 30px;
}
.wash-journeySoFar p{
    font-size: 18px;

    text-align: justify;
    font-family: "PT Serif Caption";
   
}

.photoGallery{
    width: 90%;
 
    margin: auto;
    margin-bottom: 30px;
}
/* .washSlider{
    border: 3px solid rgb(5, 5, 5);
} */
.washSlider-image-container{
    width: 25%;
    aspect-ratio: 1/0.8;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 10px;
}
.washSlider-image-container button{
    position: absolute;
    left: 45%;
    top:45%;
    background-color: rgb(108, 3, 3);
    color: white;
    border: none;
    padding: 10px;
    }
.washSlider-image-container-internalDiv{
width: 97%;
height: 100%;
/* margin: auto; */
/* border: 5px solid red; */
position: relative;
overflow: hidden;
box-shadow: 2px 2px 5px rgb(90, 88, 88);

}
.washSlider-image-container-internalDiv img{
    /* margin: 10px; */
   
height: 98%;
position: absolute;
left: 50%;
top:1%;
transform: translate(-50%);
box-shadow: 2px 2px 5px black;
 
}
@media screen and (max-width: 600px){
    .washAbout{
        margin-top: 20px;
    }
    .washAbout-content{
        position: static;
    }
    
}










