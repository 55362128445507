.ourTeam-cover{
    background-color: aqua;
    height:40vw;
max-height: 400px;
    background-image: url("../../public/images/programs/Education/Pic-1.jpg");
background-size: 100% clamp(200px,40vw,600px);
position: relative;
/* display: flex;
justify-content: center;
align-items: center; */
position: relative;
}
.ourTeam-cover-overlay{
position: absolute;
width: 100%;
height: 100%;
/* background-color: rgba(89, 98, 98, 0.804); */
background: linear-gradient(0deg, rgba(5, 5, 5, 0.737) 33%, rgba(72,89,107,0.4989010989010989) 63%, rgba(184,180,180,0) 100%);
}
.ourTeam-cover-heading{
    padding: 1vw;
    /* display: inline-block; */
    position: absolute;
    bottom: 10%;
    left: 15%;
   z-index: 20;
   border-left: 5px solid rgb(122, 117, 117);
    color: rgb(229, 137, 17);
    font-size:clamp(30px,6vw,50px);
    text-shadow: 3px 3px rgb(0, 0, 0);
margin-top: 5vh;
}

.teamContent{
    /* background-color: aqua; */
    margin-top: 2vh;
}
.teamContent-heading{
    margin-top: 5vh;
    text-align: center;
    margin-bottom: 3vh; 
}
.teamContent-heading h3{
    /* border: 2px solid black; */
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 400;
    display: inline-block;
    padding: 5px 10px 5px 10px;
    font-size: clamp(30px,5vw,40px);
    color:  rgb(255, 255, 255);
    background-color: rgb(113, 5, 50);
    /* border-radius: 10px; */

}
.add-member{
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
}
.add-member button{
    background-color: rgb(28, 156, 6);
    border: none;
    padding: 10px;
    color: white;
    transition: all 500ms ease;

}
.add-member button:hover{
    background-color: rgb(20, 111, 4);
    color: rgb(134, 251, 134);
}

.teamContent-blocks {
    /* height: 900px; */
    /* background-color: rgb(235, 229, 222); */
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* background-color: aquamarine; */
}

.teamContent-singleBlock{
     
   /* border: 5px solid rgb(2, 194, 18); */
    margin:30px 10px 30px 20px  ;
    /* background-color: rgb(117, 4, 21); */
    width:clamp(200px,25vw,320px);
    transition: all 0.5s ease;
    overflow: hidden;
    height:fit-content;
    /* padding: 10px; */
}
.teamContent-singleBlock:hover{
    /* box-shadow: 1px 1px 10px black; */
    /* border: 5px solid rgb(130, 128, 128); */
    box-shadow: 2px 2px 5px black;
    border-radius: 20px;

}
.teamContent-singleBlock-imgContainer
{
    width: 100%;
    aspect-ratio: 1/0.7;
    overflow: hidden;
}
.teamContent-singleBlock img{
    width: 100%;

}
.teamContent-singleBlock span{
    display: block;
     margin-left: 1vw;
     margin-top: 10px;
     font-size: 16px;
     color: rgb(79, 78, 79);
}
.teamContent-singleBlock button{
    display: block;
     color: rgb(117, 4, 21);
     margin: 0;
background-color: white;
     /* background-color:rgb(117, 4, 21); */
     /* padding: 5px 10px 5px 10px; */
margin: auto;
margin-top: 0;
border: none;

}
/* .teamContent-singleBlock button:hover{
    background-color: rgb(187, 189, 185) !important;
} */
.teammember-popup{
  width: 98%;
  /* background-color: blue; */
  /* height: 40px; */
    padding: clamp(10px,3vw,20px);
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    /* border: 2px solid grey; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  
}

.teammember-popup-photo-name{
    /* flex-basis: 50%; */
    width: clamp(200px,90vw,300px);
   display: flex;
   flex-direction: column;
   overflow: hidden;
    /* background-color: aqua; */
    
}
.teammember-popup-photo-name img{
    width: 100%;
   /* height: 200px; */
   /* border: 4px solid rgb(29, 151, 139); */

}
.teammember-popup-photo-name span{
    margin-top: 2vh;
   font-weight: 600;
   color:rgb(9, 9, 92);
   margin-left: 1vw;
}
.teammember-popup-about{
    padding: 10px;
    /* border-left: 2px solid grey;*/
} 
.teammember-popup-about span{
    display: block;
    font-size: clamp(12px,2vw,16px);
    
    margin-bottom: 3vh;
}
.teammember-popup-brief p{
padding: 10px;
text-align: justify;
}
.modal{
    z-index: 100000000;
    background-color: rgba(0, 0, 0, 0.5);
}
.modal-body-addmember{
   background-color: rgb(211, 221, 220);
   border: 8px solid rgb(30, 102, 95);
 padding: 0 !important;
 margin: 0!important;
    /* background-color:rgb(213, 142, 76) ; */
}

.addMember-modal-background{
    z-index: 1000000;
    position: fixed;
    top:0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.842);
    display: flex;
    justify-content: center;
    align-items: center;
}
.addMember-modal{
    margin-top: 30px;
    margin-bottom: 30px;
width: 90%;
max-width: 800px;
border: 2px solid rgb(249, 249, 252);
border-radius: 20px;
position: relative;

background-color: rgb(2, 43, 37);
}
.closebutton{
    display: block;
    float: right;
    margin: 10px;
    padding: 5px;
    background-color:rgb(2, 43, 37);
    border: 2px solid rgb(5, 101, 87);
    /* top:10px;
    right: 40px;
    color: white;
    border:none;
    
    padding: 5px;
background-color: aliceblue; */
}
.modal-body-addmember form{
    width: 90%;
    padding-top: 50px;
    padding-bottom: 50px;
   
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    /* flex-direction: column; */
}
.modal-body-addmember form label{
    margin-bottom: 10px;
    font-weight: 600;
    border-bottom: 2px solid grey;
    width: 40%;
   min-width: 200px;
   color: rgb(138, 168, 134);
    display: inline-block;
}

.modal-body-addmember form input{
    margin-bottom: 10px;
    width: 50%;
}
.modal-body-addmember form textarea{
    margin-bottom: 10px;
    width: 50%;
    height: 100px;
}
.modal-body-addmember form select{
    margin-bottom: 10px;
    width: 50%;
 
}
.modal-body-addmember form button{
     width: 200px;
     background-color: rgb(2, 129, 87);
     border: 2px solid #104803;
     color: white;
   
   position: absolute;
   left: 50%;
   bottom: 0;
   transform: translate(-50%,0%);
   margin-bottom: 10px;
}

.modal-addmember{
    /* background-color: aqua; */
    z-index: 100000000;
  
    background-color: rgba(0, 0, 0, 0.5);
}
.modal-body-addmember{
    background-color: rgb(211, 221, 220);
    /* border: 8px solid rgb(30, 102, 95); */
  padding: 0 !important;
  margin: 0!important;
}

/* @media screen and (max-width: 500px) {
    .teamContent-singleBlock{
        width:clamp(200px,90vw,450px);
    }
    
  } */