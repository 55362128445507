.profVyasHeader-heading{
    height: 200px;
    background-color:#9292c7 ;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.profVyasHeader-heading h2{
    font-weight: 700;
}
.profVyasHeader-heading h2:nth-child(1){
    color: rgb(85, 83, 83);
}
.profVyasTribute{
    width: 95%;
   
    max-width: 1200px;
    margin: auto;
    margin-top: 30px;
    padding: 20px;
    background-color: rgb(242, 247, 246);
}
.profVyasTribute img{
    max-width: 1200px;
    width: 100%;
}
.profVyasTribute p{
    background-color: rgb(231, 234, 237);
    padding: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.profVyasOration{
    /* background-color: aqua; */
    margin-top: 30px;
    margin-bottom: 30px;
}
.profVyasOration-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.annualMemorialOration{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
background-color: rgb(246, 243, 244);
}
.add-oration{
    display: flex;
    justify-content: center;
    margin: 20px 0px;
}
.add-oration button{
background-color: rgb(36, 24, 163);
padding: 5px 10px;
color: white;
/* border: 4px solid rgb(171, 189, 9); */
border: none;
transition: all 500ms ease;
}
.add-oration button:hover{
   box-shadow: 2px 2px 5px grey;
}
.annualMemorialOration-single{
    /* height: 300px; */
    /* flex: 1; */
    max-width: 400px;
   flex-basis: 90%;
   /* min-width: 250px; */
    background-color: rgb(255, 255, 255);

    /* border: 5px solid rgb(133, 101, 3); */
    /* margin: 0px 10px; */
    display: flex;
    flex-direction: column;
    margin: 30px 20px;
    /* justify-content: flex-start; */
    transition: all 500ms ease;
    padding-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    border-bottom: 1px solid grey;
    /* padding-bottom: 50px; */
    position: relative;
}
.annualMemorialOration-single:hover{
  box-shadow: 2px 2px 10px black;
}
.annualMemorialOration-single h4{
    width: 100%;
    text-align: center;
    background-color: rgb(122, 7, 7);
    padding: 10px 0px;
    min-height: 50px;
color: rgb(255, 255, 255);
text-align: center;
text-transform: uppercase;
margin: 0;
}

.annualMemorialOration-single-image{
    /* border: 2px solid red; */
    width: 100%;
    height: 75vw;
    max-height: 280px;
   /* height: auto; */
   /* aspect-ratio: 1/0.7; */
    background-color: rgb(235, 242, 237);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  
}
.annualMemorialOration-single-image img{
    /* width: 100%; */
    height: 100%;
}
.annualMemorialOration-single p{
    margin:10px;
margin-bottom: 20px;

}
.annualMemorialOration-single span{
    margin-left: 10px;
   
}
.annualMemorialOration-single a{
    text-decoration: none;
    color: rgb(119, 4, 4);
    font-weight: 600;
}
.annualMemorialOration-single button{
    position: absolute;
    /* justify-self: flex-end !important;
    align-self: flex-end !important; */
    bottom: 5px;
    left: 50%;
    transform: translate(-50%);
    /* right: 0px; */
    width: 200px;
    background-color: rgb(117, 3, 3);
    margin: auto;
    color: white;
    border: none;
    padding: 5px 0px;
}
.modal-body-addOration form{
    display: flex;
    flex-direction: column;
     /* align-items:space-between; */
    padding: 30px 10px;
}
.modal-body-addOration form input{
    margin-top: 20px;
    width: 100%;
    max-width: 600px;

}
.modal-body-addOration form label{
    width: 100%;
    padding-left: 5px;
    max-width: 600px;
    color: grey;
}
.modal-body-addOration button{
    width: 200px;
   
    margin: auto;
}
