.eventsHeader{
    width: 100%;
    overflow: hidden;
}
.eventsHeader-img-heading{
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eventsHeader-img-heading img{
position: absolute;
width: 100%;
top:-50px;
z-index: -10;
}
.eventsHeader-img-heading h2{
    z-index: 10;
    color: white;
    font-size:clamp(16px,5vw,50px) ;
    text-shadow: 2px 2px 10px black;
}