.partners{
width: 100%;
}
.partnerHeader{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    /* height: 40vw; */
    padding-top: clamp(100px, calc( 13vw + 20px )  ,190px);
    /* min-height: 200px; */
    /* max-height: 300px; */
    background-color: #9292c7 ;
}
.partnerHeader h2{
    color: white;
}
.partnerHeader p{
    color: white;
    text-align: center;
    max-width: 800px;
}
.partnerContent{
    /* background-color: aquamarine; */
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.partnerContent-subSec {
    background-color: rgb(235, 235, 236);
 
    width: 90%;
    /* max-width: 100px; */
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
.partner-single {
    margin: 10px;
    width: 280px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(225, 226, 227); */
    /* border-left: 1px solid grey;
    border-bottom: 1px solid grey; */
    padding: 10px;
}
.partner-single img{
    width: 100%;
  max-height: 250px;
}

