/* .carousel-item{
    height: 32rem;
    background: #777;
} */
.corousel-made{
    /* background-color: rgb(0, 0, 0); */
    /* border: 4px solid rgb(12, 12, 83); */
    width: 100%;
    height: 100%;
    /* border: 2px solid green; */
    display: flex;
}

.carousel-item-made{
/* background-color: rgb(9, 9, 9); */
   flex-basis: 100%;
    height: 100%;
    /* border: 2px solid rgb(0, 52, 0); */
}
.carousel-item-made img{
  
    width: 100%;
 height: 100%;
    height: calc( 140% - 100px ) ;
  
}
@media screen and (max-width:1100px){
    .carousel-item-made img{
        height: 100%;
    }
}

