/* 


.card-text{
    justify-content: center;
    text-align: justify;
    padding: 1rem;
    /* color: rgb(255, 5, 5) !important; */

    .card-body{
        background:url('../../public/images/home/homeEventBg.jpg');
        background-size: 100% 100%;
       /* border: 5px solid brown; */
       overflow: hidden;
       height: 150px;
    }
    .singleCard{
 
        max-width: 400px;
        height: 100%;

        margin:15px;
        transition: 0.1s;
        color: white;
        margin: auto;
        /* background-color: aqua; */
        overflow: hidden;
        /* 
       */

        /* border-color: black; */
     }
    
    .singleCard:hover{
    
    
        box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
    }
    .singleCard-img-container{
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px !important;
        /* border:2px solid black; */

    }
  
    .singleCard-img-container img{
    /* height: 90% !important; */
  height: 100%;
    transform: scale(1);
    transition: transform 0.5s ease;
}

.singleCard img:hover{
    transform: scale(1.5);

}
.singleCard p{
    color: rgb(83, 82, 82);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-align: justify;
     -webkit-box-orient: vertical;
    overflow: hidden;
}
.singleCard h4{
    color: rgb(44, 48, 44);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-align: center;
     -webkit-box-orient: vertical;
    overflow: hidden;
}


