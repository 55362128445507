.videoHeader{
    width: 100%;
    height: clamp(170px,60vw,500px);
    /* overflow: hidden; */
    position: relative;
    /* border: 2px solid rgb(4, 179, 19); */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
   
}
.videoHeader{
    height: clamp(300px,80vw,720px);
    /* background-color: aqua; */
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.videoHeader-content{
    z-index: 1000;
}
.videoHeader-content h1{
    font-size: clamp(30px,10vw,70px);
    text-shadow: 2px 2px black;
    padding: 0;
    margin: 0;
    color: white;
}

.Cover-VideoPage{
    position: absolute;
    height: 100%;
    width: 100%;
}
.videoHeader-CoverpicContainer{
    position: absolute;
    width: 100%;
    height: clamp(170px,60vw,500px);
    overflow: hidden;
    /* border: 6px solid red; */
}
.videoHeader-CoverpicContainer img{
    position: absolute;
    /* height: 100%; */
    width: 100%;
}
.videoHeader-headingContainer{
z-index: 10;
}
.videoHeader-headingContainer h2{
    font-size: clamp(25px,6vw,60px);
    color: white;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-shadow: 2px 2px 10px black;
}






.modal-body-addVideo form{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }
  .modal-body-addVideo form input{
      margin-top: 10px;
      width: 100%;
      max-width: 700px;
      padding: 5px;
  }
  .modal-body-addVideo form select{
    margin-top: 10px;
    width: 100%;
    max-width: 700px;
    padding: 5px;
}
  .modal-body-addVideo form textarea{
      margin-top: 10px;
      width: 100%;
      height: 100px;
      max-width: 700px;
  }
  .modal-body-addVideo form button{
      width: 200px;
      margin: auto;
      margin-top: 10px;
  }
.addVideoButton{
    width: 100%;
    margin: 20px 0px;
    /* background-color: antiquewhite; */
    display: flex;
    justify-content: center;
}
.addVideoButton button{
    border: none;
    background-color: rgb(4, 4, 110);
    color: white;
    padding: 5px 10px;
}

  .videoContent{
   /* border: 5px solid rgb(114, 111, 111); */
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }

  .videoContent-eventContainer-singleEvent{
    width: 90%;
    margin: auto;
    margin: 30px 10px;
    max-width: 400px;
    background-color: rgb(255, 255, 255);
    height: auto;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 500ms ease;
    border-radius: 10px;
    overflow: hidden;
    border-bottom: 1px solid grey;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-between;
    aspect-ratio: 1/0.8;
  }
  .videoContent-eventContainer-singleEvent:hover{
    box-shadow: 2px 2px 10px black;
  }
  .videoContent-eventContainer-singleEvent h4{
   
margin: 0;
min-height: 40px;
text-transform: uppercase;
font-weight: 700;
font-size: 20px;
display: block;
text-align: center;
   background-color: rgb(168, 9, 9);
   color: white;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
  }
  .videoContent-eventContainer-singleEvent h6{
 
    margin: 0;
    min-height: 30px;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    
      }

.videoContent-eventContainer-singleEvent p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-align: justify;
    margin: 10px;
     -webkit-box-orient: vertical;
    overflow: hidden;
    color: rgb(0, 0, 0);
    font-size: 18px;
}

.videoContent-eventContainer-singleEvent-footer{
    width:100%;
    /* background-color: blueviolet; */
    border-top: 1px solid rgb(198, 3, 3);
    padding: 10px;
    height: auto;
    align-self: flex-end;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.videoContent-eventContainer-singleEvent-footer a{
    text-decoration: none;
    color: blue;
    font-size: 20px;
}
.videoContent-eventContainer-singleEvent button{
    border: none;
    color: white;
    background-color: rgb(208, 18, 18);
    padding: 5px 10px;
    margin: 5px;
}

.videoContent-eventContainer-singleEvent-iframe{
    width: 100%;
height: 100%;

}


  
  
  