
.programsContainer{
    background-color: rgb(139, 137, 141);
 width: 100%;
 
  height: clamp(150px,60vw,700px);
  min-height: 150px;
  max-height: 800px;

 object-fit: cover;
 overflow: hidden;
 position: relative;
 }
 .programs-heading-container{
     /* background-color: #3360c2; */
     padding: 1vw;
     display: inline-block;
     position: absolute;
     bottom: 30%;
     left: 50%;
    z-index: 20;
    transform: translate(-50%);

 }
 .programs-heading-container h1{
     color: rgb(253, 253, 253);
     font-size:clamp(30px,6vw,50px);
     text-shadow: 2px 2px 1px rgb(0, 0, 0);
   
 }
 .programs-heading-container span{
    font-size:clamp(18px,3vw,30px);
    color: #dfd5d5;
    text-shadow: 2px 2px #070707;
}
 .programsContainer-overlay{
    width: 100%;
     height: 100%;
     position: absolute;

     z-index: 10;
    background-color: #1c1b1ba4;
}


.programsHeader-img-heading{
width: 100%;
height: 300px;
z-index: 10;
overflow: hidden;
position: relative;
display: flex;
justify-content: center;
align-items: flex-end;
}
.programsHeader-img-heading img{
    width: 110vw;
    height: 110vw;
    position: absolute;
    left:-10vw;
    top:-10vw;
}
.programsHeader-img-heading-heading{
    color: rgb(255, 255, 255);
   
z-index: 10;
display: flex; 
 justify-content: center;
align-items: center;
background-color: rgba(26, 28, 28, 0.275);
margin-bottom: 80px;
padding: 5px 20px 5px 20px;
border: 2px solid white;
/* padding-left: 20px;
padding-right: 20px; */
}
.programsHeader-img-heading-heading h2{
    font-size: clamp(30px,6vw,40px);
    padding: 0;
    margin: 0;
    font-weight: 700;
   
}
/* .programs-HomePrograms{
    position: relative;
    bottom:180px;
} */
.programsContent{
    width: 100%;
  max-width: 1200px;
  margin: auto;
  background-color: #eaeeec;
  padding: 20px;
  margin-top: calc(20px + 2vw);
}
.programsContent-internal-div{
    display: flex;
   /* flex-wrap: wrap; */
   justify-content: space-evenly;

   /* background-color: rgb(60, 144, 217); */
   height: auto;
}
.programsContent-internal-div div{
    padding : 10px;

    background-color:  #6cb68e;
    flex-basis: 30%;
    /* min-width: 250px;
    max-width: 400px; */
    /* height: 100%; */
}
.HiArrowCircleRight{
  font-size: 40px;
  color: #6cb68e;
  color: #256d47;
  /* color: #eaeeec; */
  /* background-color: #6cb68e; */
    align-self: center;
   
}
.HiArrowCircleDown{
    display: none;
    font-size: 40px;
  color: #6cb68e;
  color: #256d47;
  /* color: #eaeeec; */
  /* background-color: #6cb68e; */
    align-self: center;
}
.modal-body-addProgramImage form{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }
  .modal-body-addProgramImage form input{
      margin-top: 10px;
      width: 100%;
      max-width: 700px;
  }
  .modal-body-addProgramImage form select{
    margin-top: 10px;
    width: 100%;
    max-width: 700px;
}
  .modal-body-addProgramImage form textarea{
      margin-top: 10px;
      width: 100%;
      height: 100px;
      max-width: 700px;
  }
  .modal-body-addProgramImage form button{
      width: 200px;
      margin: auto;
  }
.add-program-image{
  
    background-color: rgb(151, 5, 5);
    color: white;
    border: none;
    padding: 10px;
       width: 200px;
    display: block;
    margin: auto;
}
  @media screen and (max-width: 750px) {
    .HiArrowCircleDown{
        display: inline-flex;
        margin: 20px;
    }
    .HiArrowCircleRight{
        display: none;
    }
    .programsContent-internal-div{
        flex-direction: column;
    }
  }