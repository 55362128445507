
.achievementsHeader{
 
    width: 100%;
    height: clamp(250px,40vw,400px) ;
    overflow: hidden;
position: relative;
display: flex;
justify-content: flex-end;
align-items: flex-end;
}
.achievementsHeader-img{
    width: 100%;
   height: 200%;
z-index: -10;
position: absolute;
top:-50%;
}
.achievementsHeader-heading{
    /* width: 200px;
    height: 100px; */
    box-sizing: border-box;
    z-index: 10;
    margin-bottom:9%;
    margin-right: calc(14% - 30px);
    
}
.achievementsHeader-heading h1{
    color: aliceblue;
    font-size: clamp(0px,7vw,50px);
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    color: rgb(10, 128, 161);
    background-color:rgb(198, 233, 242);
    border-left: 15px solid rgb(10, 128, 161);
    /* border-bottom:  5px solid rgb(102, 202, 230); */
    border-right: 15px solid rgb(10, 128, 161);
    /* border-top:  5px solid rgb(10, 128, 161); */
    border-radius: 70px;
    padding-left: 15px;
    padding-right: 15px;
}