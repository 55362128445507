.dots{
    text-align: center;
    /* background-color: blue; */
    font-size: large;
    display: flex;
    justify-content: center;
}
.dot1{
background-color: rgb(89, 84, 84);
margin: auto 8px auto 8px;
width: 10px;
height: 10px;
border-radius: 5px;
}