
.navBar{
    width: 100%;
    /* overflow: hidden; */
    height: clamp(50px,23vw,110px);
    position: fixed;
    /* padding:5px 10px; */
    background-color: rgba(255, 255, 255,0.4);
    z-index: 2000;
    top:0px;
    display: flex;
    justify-content: space-between;
    box-shadow: 2px 2px 10px black;
    transition: all 1000ms ease;
padding-right: 20px;
/* padding-left: 20px; */
background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,0.6791208791208792) 19%, rgba(246,127,194,1) 55%, rgba(236,138,201,1) 100%);
background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,0.6791208791208792) 19%, rgba(18,73,143,1) 55%, rgba(18,73,143,1) 100%);
background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,0.6791208791208792) 19%, rgba(108,182,142,1) 55%, rgba(108,182,142,1) 100%);
background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,0.6791208791208792) 19%, rgba(146,146,199,1) 55%, rgba(146,146,199,1) 100%);
}
.changeNav{
    background-color: rgb(220, 141, 190);
    padding-right: 10px;
   height: clamp(45px,18vw,75px);;
    padding-left: 10px;
}
.nav-logo{
    /* background-color: aqua; */
    height: 100%;
flex-basis: 20%;
display: flex;
justify-content: center;
cursor: pointer;
/* background-color: rgb(253, 148, 0); */

padding: 5px 10px;
}
.nav-logo img{
   
    height: 100%;
 
}
.navBar-titles{
    padding: 1px;
    flex-basis: 80%;
    height: 100%;
    /* background-color: blueviolet; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.navBar-titles  ul{
    /* background-color: bisque ; */
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    /* overflow: hidden; */
    display: flex;
    align-items: center;

    justify-content: flex-end;

  
}
.navBar-titles ul li{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    position: relative;
    text-align: center;
/* margin-left:calc(4vw - 15px) ; */
transition: all 500ms ease;
}
.navBar-titles ul li a{
    text-align: center;
    text-decoration: none;
    color: black;
    font-size: 17px;
    font-weight: 600;
}
.navBar-titles ul li:hover{
    background-color: rgb(102, 4, 4);
    color: white;
}
.navBar-titles ul li:hover a{
    color: rgb(255, 254, 254);
}

/* .ourwork:hover .ourWork-extension{
    display: block;
} */
/* .ourwork{
    overflow: hidden;
} */
/*  */
/* .ourwork:hover {
    overflow: visible;
} */


.extension{
    z-index: 200000;
    position: absolute;
    display: none;
    transform: translate(-38%);
    /* 
    */
   
    left: 100px; 
    top:65px;
    width: 400px;
   padding: 5px 10px;
   background-color: rgb(230, 224, 230);
   
}
.navAbout-extension{
    position: absolute;
    left:-100px !important;
}
.extension ul{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.extension ul li{
  flex: 1;
  height: 100%;
   /* border: 2px solid pink; */
    text-transform: uppercase;
    /* width: 100px; */
    padding: 4px;
margin: 5px;
text-align:left !important;
display: block;
    width: 100%;
color: rgb(58, 57, 57) !important;
/* background-color: pink; */
}

.extension ul li a{
    text-align:left !important;
    /* color: rgb(128, 8, 86); */
    font-weight: 700;
    color: rgb(58, 57, 57) !important;
    padding: 3px 5px ;
    display: block;
    
}
.extension ul li:hover{
    background-color: rgb(115, 6, 33);
}
.extension ul li:hover a{
   color: rgb(255, 255, 255) !important;
}
.ourwork:hover .ourWork-extension{
    display: flex;
    flex-direction: column;
}
.navMedia:hover .navMedia-extension{
    display: flex;
}
.navInsight:hover .navInsight-extension{
    display: flex;
}
.navAbout:hover .navAbout-extension{
    display: flex;
}
.navBar-events{
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    /* color: white; */
}


.navBar-events ul{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.navBar-events ul li{

    display: block;
    width: 100%;
  flex: 1;
  height: 100%;
    text-transform: uppercase;
    /* width: 100px; */
    padding: 4px;
/* margin: 5px; */

}
.extension p{
margin: 0;
padding: 0;
    font-weight: 700;
}


/*---------------------DRAWER-------------------*/
.draw-Navbar{
    width: 100%;
    position: fixed;
    top:0;
    left:0;
    height: 40px;
    z-index: 10000;
    background-color: rgba(0, 0, 0,0.6);
    box-shadow: 2px 2px 20px rgb(252, 252, 252);
    display: flex;
    flex-wrap: wrap;
    /* position: absolute;
    top:0; */
    height: auto;
  
}
.crossIcon{
    color: rgb(254, 254, 254);
    height: 40px;
    flex-basis: 50%;
   background-color:#12498f;

  
}
.draw-Navbar-logo{
    background-color: rgb(254, 253, 250);
    flex-basis: 50%;
    height: 40px;
   
}
.draw-Navbar-logo img{
    height: 100%;
   padding: 2px;
}
.draw-Navbar-titles{

    height: 0;
    /* overflow: hidden; */
   
   z-index: 100000;
   position: fixed;
   /* top:10%; */
   top: 40px;
   left:0;
   width: 100%;
   height: 90%;
   margin: auto;
   background-color: #9292c7 ;
   
    /* height: 200vh; */

    overflow:scroll;
    /* height:0; */
  transition: all 1s ease;
}


.draw-Navbar-titles nav ul{
   
    /* overflow: scroll; */
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
}

.draw-Navbar-titles nav ul p{
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 700;
    color: grey;
}
.draw-Navbar-titles nav ul h6{
    margin: 0;
    padding: 0;
    font-size: 30px !important;
    font-weight: 600 !important;
    color: white !important;
   /* border-bottom: 3px solid rgb(3, 3, 240); */
   color: #12498f !important;
   margin-top: 20px;
   text-decoration: none;
}
.draw-Navbar-titles nav ul h6 a{
    margin: 0;
    padding: 0;
    font-size: 30px !important;
    font-weight: 700 !important;
    color: #12498f !important;

 
}
.draw-Navbar-titles nav ul li{
    width: 100%;
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin: 5px;
    font-size: 18px;
}
.draw-Navbar-titles nav ul li a{
    margin: 0;
    padding: 0;
    color: rgb(253, 253, 253);
    text-decoration: none;
    font-weight: 600;
    
}

.draw-Navbar-titles nav ul li:hover{
    background-color: rgb(50, 50, 236);
}








/* 

.navBar{
    overflow: hidden;
    position: fixed;
     top: 0; 
    width: 100%;
   z-index: 10000000;
    transition: 0.8s ;
   
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 2px 10px black;
    color: grey;
    height: 90px;
    transition: all 1s ease;
}

.navBar nav{
    width: 100%;
    height: 80px;
    line-height: 90px;
}
.logo{
    width: 80px;
    height: 70px;
    margin-left: 8vw;
     
    filter: drop-shadow(3px 3px 30px rgb(238, 238, 238));

 transition: all 1s ease;
}

.navBar ul{
    float: right;
    margin-right: 50px;
    transition: margin-right 1s ease;
}
.navBar nav ul li{
    list-style-type: none;
    display: inline-block;
    font-size: 18px;
    transition: 0.8s all;
   
}
.navBar nav ul li:hover{
    background-color: #C2B9B0;
   
}
.navBar nav ul li a{
    text-decoration: none;
    color: rgb(254, 254, 254);
    color: black;
    padding: 30px;
}
.changeNav{
    background-color: rgb(151, 147, 146);
    background-color: rgb(241, 132, 174);
    height: 90px;
}
.changeMargin{
    margin-right: 30px !important;
}
.changeImgMargin{
    margin-left: 6vw;
}
.toggleIcon{
    
    color: rgb(3, 38, 68);
    background-color: rgb(31, 215, 215);
 
    float: right;
    height: 8vw;
    margin: auto;
    cursor: pointer;
    display: none !important;
}
.crossIcon{
    display: none;
}
@media screen and (max-width: 850px) {
    .navBar nav ul li a{
        padding: 2vw;
    }
  }
  @media screen and (max-width:670px) {
    
    .navBar nav ul{
        display: none;
    }
    
      .toggleIcon{
        display:block !important;
        color: white;
        background-color: rgb(8, 6, 109);
        
        margin-right: 10vw;
         margin-top: 20px;
  
    }
    .crossIcon{
            display:block;

            color: white;
          
       position: absolute;
       top:20px;
       right: 10vw;
       height: 8vw;
       margin: auto;
       cursor: pointer;

    }

      .draw{
          position: fixed;
          position: sticky;
          background-color: black;
        
          height: 100vh;
          overflow: hidden;
       
      }
      .draw img{
       
          display: block;
          margin: auto;
          width: 40vw;
          height: 30vw;
          filter:none;
      }
      .draw nav{
       
       
        width: 100vw;
        color: white;
      }
      .draw  ul{
        
        display: flex;
          flex-direction: column;
          justify-content: space-evenly;
    
        color: white;
      }
      .draw nav ul li{
    
     
        list-style-type: none;
          font-size: 5vw;
          color: white;
          transition: 0.8s all;
          display: block;
          margin: auto;
          margin-top: 5vh;
      }
   

    .draw nav ul li a{
    
        text-decoration: none;
        color: rgb(255, 255, 255);
       
    }
  
     
  }

 */
