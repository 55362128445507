.HomePageCoverage{
    width: 100%;
    /* height: 550px; */
    /* background-color: rgb(23, 173, 190); */
    background-color: rgb(204, 205, 205);
    display: flex;
 
    justify-content: center;
    align-items: center;
    padding: 120px 0px;
    flex-wrap: wrap;
   
}
.HomePageCoverage-content{

    width: 100%;
    height: 25vw;
    /* min-height: 300px; */
    /* max-height: 500px; */
    /* border:2px solid red; */
    background-color: rgb(242, 239, 239);
    display: flex;
    position: relative;
    justify-content: space-evenly;
    align-items: center;
  
}
.HomePageCoverage-content-text{
    text-align: center;
    flex-basis: 30%;
    display:flex;
    flex-direction: column;
    min-width: 250px;
justify-content: center;
align-items: center;
    /* background-color: aquamarine; */
}
.HomePageCoverage-content-text ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.HomePageCoverage-content-text ul li{
    list-style-type: none;
    color: rgb(95, 0, 44);
  font-size: 18px;
    margin: 0;
    position: relative;
    right: 15px;
}
.HomePageCoverage-content-text h2{
    font-weight: 700;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}
.HomePageCoverage-content-text p{
    font-size: 20px;
    margin: 0;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}
.HomePageCoverage-content-map{
    flex-basis: 50%;
    /* background-color: rgb(193, 193, 193); */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 25vw;
    /* min-height: 300px; */
    /* background-color: rgb(232, 232, 232); */
}
/* .HomePageCoverage-content-text{
    flex-basis: 39%;
    display: flex;
    justify-content: center;
 
} */
.HomePageCoverage-content-map img{
    /* width: 100%; */
    height: 160%;
}
/* .HomePageCoverage-content-text ul{
    width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: space-evenly;
} */



.HomePageCoverage-content-map li{
    font-size: clamp(1px,2vw,25px);
   
    font-weight: 700;
    position: absolute;
    /* top:0;
    left: 0; */
    color: rgb(146, 77, 161) !important;
    /* color: rgb(178, 92, 132) !important; */
    text-shadow: 1px 1px 1px black;
    list-style-type: none;

}
.HomePageCoverage-content-map li:nth-child(1)
{
    
    left:30%;
    bottom: 2%;
}
.HomePageCoverage-content-map li:nth-child(2)
{
    left:47%;
    bottom: 23%;
   
}
.HomePageCoverage-content-map li:nth-child(3)
{
    left:75%;
    bottom: 41%;
}
.HomePageCoverage-content-map li:nth-child(4)
{
    left:58%;
    bottom: 33%;
}
.HomePageCoverage-content-map li:nth-child(5)
{
    left:68%;
    bottom: 65%;
}
.HomePageCoverage-content-map li:nth-child(6)
{
    left:68%;
    bottom: 51%;
}
.HomePageCoverage-content-map li:nth-child(7)
{
    left:49%;
    bottom: -5%;
}
.HomePageCoverage-content-map li:nth-child(8)
{
    list-style-type: none;
    left:30%;
    top: 0%;
    font-size: 35px;
    /* color: rgb(255, 254, 254) !important; */
}
.HomePageCoverage-content-areaDetail{
    /* background-color: black; */

    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 20%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 30%;
}
.HomePageCoverage-content-areaDetail-single{
    border: 2px solid rgb(255, 255, 255);
    background-color:rgb(229, 229, 234);
    border-radius: 20px;
    padding: 20px;
    
}
.HomePageCoverage-content-areaDetail-single h4{
font-weight: 600;
color: rgb(68, 68, 68);
}
.HomePageCoverage-content-areaDetail-single ul{
    display: flex;
    flex-direction: column;
}
.HomePageCoverage-content-areaDetail-single ul li{
    list-style-type: square;
   
    /* color: white; */
}
/* .HomePageCoverage-content-areaDetail-width{
    flex-basis: 20%;
} */

@media screen and (max-width:900px){
    .HomePageCoverage{
        padding: 0;
        display: block;
        /* background-color: bisque; */
        /* border: 2px solid cadetblue; */
        height: auto;

    }
    .HomePageCoverage-content{

        /* width: 100%; */
        height: 100%;
        
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        /* background-color: aquamarine; */
    }
    .HomePageCoverage-content-areaDetail{
        position: static;
        
flex-basis: 100%;
        /* background-color: rgb(8, 88, 88); */
display: flex;
        flex-wrap: wrap;
        transform: translate(0);


    }
    .HomePageCoverage-content-areaDetail-single{
        margin: 10px;
        width: 30%;
        min-width: 250px;
        height: 250px;
    }
}


