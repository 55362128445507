.timeline{
    background-color: rgb(245, 249, 249);
    padding-top: 30px;
    /* font-family: "Montserrat"; */
    /* font-family: sans-serif; */
    color: rgb(79, 77, 77);
    font-size: 5px;
    /* margin: 5vh auto 5vh auto; */
}

.VerticalTimelineElement h3{
padding-top: 0.2em;
font-size: 25px;
/* color: rgb(28, 183, 116); */
color: rgb(73, 46, 118)
}
/* .VerticalTimelineElement h5{
    
} */
.VerticalTimelineElement p{
  font-family: sans-serif;
  color: rgb(252, 250, 250);
    }
.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
      0 0.4em 1.25em 0 rgba(158, 157, 157, 0.15) !important;
    padding: 3em 4em !important;
    background-color: rgb(187, 160, 230) !important;
    border-radius: 50px !important;
    padding: 20px !important;
    /* border: 5px solid white; */
  }
  .date{
    color: rgb(73, 46, 118)
  }