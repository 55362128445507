.homePage{
    /* height: 100vh; */
    width: 100%;
    background-color: #ffffff;
   
}
.homePageHeader{
  /* height: 95vh; */
  height: clamp(300px,80vw,720px);
  overflow: hidden;
  position: relative;
  /* border: 2px solid red; */
}
.logoutButton{
  position: absolute;
  top:clamp(60px,18vw,200px);
  right:clamp(20px,2vw,1000px);

  border: none;
  background-color: rgb(152, 5, 5);
  color: white;
  padding: 5px 10px;
  z-index: 200;
}
.Cover-MainPage{
  position: absolute;
  width: 100%;
  height: 100%;

}
.Cover-MainPage img{
    width: 100%;
    height: 100%;
    /* height: clamp(); */
    /* z-index: -10; */
    /* position: fixed; */
    /* top:0; */
}
.homePageHeader-content {
  z-index: 1000;
  width: 95vw;
  max-width: 1200px;
/* background-color: #ff0080; */
margin-left: 10%;
margin-right: 20px;
   position: absolute;
   bottom: 0;
  /* background-color: aquamarine; */
}
.homePageHeader-content h1{
  display: inline-block;
  /* background-color: bisque; */
  color: white;
  text-transform: uppercase;
  font-size: clamp(18px,5vw,55px);
  text-shadow: 3px 3px 5px black;


  overflow: hidden; 
  border-right: .15em solid rgb(134, 104, 48); 
  white-space: nowrap; 
  margin: 0 auto; 
  letter-spacing: .1em; 
  animation: typing 3.5s steps(40,end) , blink-caret .75s step-end infinite;



}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(100, 100, 100); }
}



.homePageHeader-content h4{
  margin-top: 10px;
  color: rgb(255, 254, 254);
  /* text-transform: uppercase; */
  font-style: italic;
  text-shadow: 2px 2px 1px #012813;
  font-size: clamp(18px,4.6vw,38px);
  color: #ff0080;
  color:#6cb68e  ;
  /* color: #9292c7 ;
  color: #12498f; */
  /* #6cb68e 
violet - #9292c7 
blue - #12498f */

}
.homePageHeader-content p{
  width: 80vw;
  max-width: 890px;
  margin-top: 50px;
  color: rgb(255, 255, 255);
    background-color: rgba(55, 55, 55, 0.338);
    padding: 10px;
  font-size: 18px;
  text-shadow: 2px 2px 10px black;
  text-align: justify;
}
.extratextHomePageHeader{
  background-color: rgb(175, 175, 180);
  width: 100%;
  height: 100%;

  margin: 0 !important;
  padding: 0;
   padding: 20px 5px;
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
}
.extratextHomePageHeader p{
  text-align: center;

  margin: 0 !important;
  padding: 0;
}
.Content-HomePage{

    /* margin-top: 100%;
    margin-top: calc((80% - 100px));
    margin-top: clamp(160px, 50vw, 700px);
    z-index: 1; */
    background-color: rgb(215, 219, 221);
    /* padding-top: 5vh; */
    /* padding-top: 10px; */
}
.homePageHeadline{
    background-color: rgb(4, 82, 141);
    width: 100%;
    /* display: flex;
    flex-direction: column; */
    overflow: hidden;
    /* 
    align-items: center; */
    /* height: 50px; */
   position: relative;
    padding: 1px;
}
.homePageHeadline-internaldiv{
    /* width: 140vw; */
    margin: 10px;
    /* background-color: aqua; */

    animation: example infinite ;
    animation-timing-function: linear;
    /* clear: both; */
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    animation-delay: 2s;
    margin-left: 100vw;
    /* position: absolute; */
    /* left: 0;
    top:0; */
}
.homePageHeadline p{
    margin: 0;
    padding: 0;
    color: white;
    /* clear: both; */
    display: inline-block;
    /* overflow: hidden; */
    /* white-space: nowrap; */
}
@keyframes example {
    0%   { transform: translate(0%); }
    /* 25%   { transform: translate(-25%); }
    50%   { transform: translate(-50%); }
    75%   { transform: translate(-75%); } */
    100% { transform: translate( calc( -100% - 100vw ) ); }
  }
  .homePageHeadline button{
    background-color: rgb(219, 163, 9);
    padding:5px 10px;
    border: none;
    color: white;
    width: 100px;
   margin: auto;
   position: relative;
   left:50vw;
   transform: translate(-50%);
  }
  .modal-body-addHeadline form {
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
  }
  .modal-body-addHeadline form textarea{
    height: 100px;
    /* background-color: blue; */
margin-bottom: 10px;
  }
  .modal-body-addHeadline form button{ 
    background-color: rgb(3, 109, 145);
    color: white;
  }

  .homePageApproach{
   
    width: 100%;
    margin: auto;
   
    padding-top: 30px;
    padding-bottom: 30px;
    background-color:rgb(255, 255, 255);
  }
  .homePageApproach h3{
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    
    color: rgb(88, 87, 87);
  }
  .homePageApproach p{
    font-size: 18px;
    color:rgb(82, 47, 1);
  max-width: 800px;
  margin: auto;
    text-align: justify;
  
  }
  .homePageApproach  p b{
    text-transform: uppercase;
    color: rgb(3, 75, 93);
  }

  /*-----------------IMAPCT STORY-----------------------------*/

  .homepageImpactStory{
    /* height: 500px; */
    background-color: white;
    display: flex;
    
  }
  .homepageImpactStory-leftside{
    flex:1;
   flex-basis: 40%;
   padding: 10px 5px;
   background-color: rgb(209, 22, 116);
   
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
  }
  .homepageImpactStory-leftside h4{

   font-size: clamp(18px,3.6vw,40px);
   text-transform: uppercase;
   color: white;
   font-weight: 1000;
   font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  .homepageImpactStory-leftside p{
width: 90%;
max-width: 400px;
font-size: 20px;

color: white;
  }
  .homepageImpactStory-rightside{
    flex:1;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-basis: 60%;
    background-color: rgb(234, 245, 226);
  }
  .homepageImpactStory-rightside img{
   width: 80%;
   border-radius: 20px;
   box-shadow: 2px 2px 10px  rgb(62, 62, 62);
  }
  .homepageImpactStory-rightside p{
    margin-top: 20px;
    width: 70%;
    color: grey;
    font-size: 20px;
  }
  .homepageImpactStory-rightside button{
    font-weight: 600;
    border: none;
    color: rgb(249, 249, 249);
    background-color: rgb(113, 6, 45);
    width: 100px;
    height: 40px;
    align-self: flex-start;
    margin-left: 8vw;
  }
  .homePageSustainableGoals{
    border: 1px solid rgb(251, 250, 250);
    width: 100%;
    background-color: rgb(237, 244, 242);
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .homePageSustainableGoals-heading{
    width: 100%;
  
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
  
/* margin: 20px 5px; */
  }
  .homePageSustainableGoals-heading h4{
    font-weight: 700;
    text-align: center;
    font-size:clamp(16px,2vw,20px);
    /* flex-basis: 45%; */
    width: 60vw;
    color: rgb(7, 16, 81);
  }
  .homePageSustainableGoals-heading img{
   
 
    flex-basis: 35%;
    width: 30vw;
  }
  .homePageSustainableGoals-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .SDGLogo-single{
    width: 95%;
    margin: auto;
    max-width: 100px;
    margin: 10px;

  }
  .SDGLogo-single img{
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    .homepageImpactStory{
      flex-direction: column;
    }
    .homepageImpactStory-leftside h4{
      font-size: clamp(22px,6vw,40px);
      color: green;
    }
  }
  @media screen and (max-width: 400px){
    .homePageHeader-content h1{
      /* display: flex !important;
      flex-wrap: wrap; */
      display: block;
      font-size: 10vw;
    }

  }