*{
    box-sizing: border-box;
}
.impactStories{
   width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.impactStoriesHeader{
    height: clamp(300px,80vw,720px);
    /* background-color: aqua; */
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.impactStoriesHeader-content{
    z-index: 1000;
}
.impactStoriesHeader-content h1{
    font-size: clamp(30px,10vw,70px);
    text-shadow: 2px 2px black;
    padding: 0;
    margin: 0;
    color: white;
}

.Cover-StoryPage{
    position: absolute;
    height: 100%;
    width: 100%;
}
.impactStoriesHeader-image-heading{
    width: 100%;
    position: relative;
    height: 300px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.impactStoriesHeader-image-heading img{
    width: 100%;
    position: absolute;
    
}
.impactStoriesHeader-image-heading-heading{
    z-index: 10;
    margin-bottom: 80px;
    border: 2px solid white;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(0, 0, 0, 0.499);
}
.impactStoriesHeader-image-heading-heading h2{
    font-size: clamp(30px,6vw,40px);
    padding: 0;
    margin: 0;
    color: white;
}
.impactStories-addStory{
    background-color: rgb(247, 230, 135);
    color:  rgb(104, 104, 2);
    border: 4px solid rgb(155, 155, 2);
    padding: 5px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.modal-body-addStory form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.modal-body-addStory form input{
    margin: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    width: 70%;
    height: 40px;
    padding: 5px;
}
.modal-body-addStory form textarea{
    margin: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    width: 90%;
    height: 100px;
    padding: 5px;
}
.modal-body-addStory form button{
    padding: 5px 10px;
    align-self: center;
    margin: 5px; 
    transition: all 200ms ease;
}

.modal-body-addStory form button:hover{
    transform: scale(1.1);
  
    /* */
}

/* color: #9292c7 ;
  color: #12498f; */
  /* #6cb68e 
violet - #9292c7 
blue - #12498f */
.impactStoriesBlock{
    /* background-color: rgb(48, 70, 66); */
    background-color: #d1d9e4 ;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}
.impactStoriesBlock-page-number {
    margin-top: 30px;
    margin-left: clamp(5%,5%,100px);
    width: 100px;
    display: flex;
}
.impactStoriesBlock-page{
    width: 50%;
    background-color: rgb(55, 115, 3);
    text-align: center;
    color: rgb(250, 250, 249);
}
.impactStoriesBlock-number{
    width: 50%;
    background-color: rgb(255, 255, 255);
    text-align: center;
    color: rgb(55, 115, 3);
}
.impactStoriesBlock-page-number span{
    font-weight: 700;
}

.sticky-pagination{
    position: -webkit-sticky;
  position: sticky;
  right: 2px;
  bottom: 40vh;
  width: 25vw;
  background-color: rgb(241, 132, 174);
  margin-right: 5vw;
  padding-top: 10px;
  padding-bottom:10px ;
  margin-left: auto;
display: flex;
justify-content: center;
align-items: center;
border: 5px solid rgb(173, 198, 167);

position: initial;
    
width: 100%;
background-color: rgb(227, 223, 224);

padding-top: 10px;
padding-bottom:10px ;

display: flex;
justify-content: center;
align-items: center;
border: none;
box-shadow: 1px 1px 15px rgb(93, 91, 91);

}
.story{
    /* background-color: rgb(26, 15, 2); */
    background-color: #cee9db ;
    margin: 20px;
    width: 90%;
    max-width: 1100px;
    margin-left: clamp(5%,5%,100px);
    margin-right: 5% ;
    /* height: 300px; */
    /* border: 5px solid rgb(197, 220, 195); */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
transition: all 500ms ease;
overflow: hidden;
/* border-right: 1px solid rgb(141, 2, 2); */
  
    /* background-color: rgb(93, 129, 123); */
    position: relative;
}
.story:hover{
    box-shadow: 2px 2px 10px black;
    border-radius: 20px;
}
.story-border{
  width: 100px;
  height: 100px;
  position: absolute;
  
 
  border-right: 1px solid rgb(141, 2, 2);
}
.story-border:nth-child(1){
    bottom: 0;
    right: 0;
    border-bottom:  1px solid rgb(141, 2, 2);
  }
  .story-border:nth-child(2){
    top: 0;
    right: 0;
    border-top:  1px solid rgb(141, 2, 2);
  }

.story hr{
    color: rgb(141, 2, 2);
}
.story-content{
    /* border: 2px solid grey; */
flex-basis: 60%;
padding: clamp(10px,1vw,15px);
}
.story-content h3{
    text-transform: capitalize;
    /* color: rgb(48, 70, 66); */
    color:  rgb(0, 0, 0);
    /* text-shadow: 2px 2px 5px rgb(6, 6, 6); */
}
/* color: #9292c7 ;
  color: #12498f; */
  /* #6cb68e 
violet - #9292c7 
blue - #12498f */
.story-content h5{
    color: #6cb68e ;
}
.story-content p{
    padding-right: 10px;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 7;
     -webkit-box-orient: vertical;
    overflow: hidden;
    color: white;
    color: rgb(90, 88, 88);
}
.story-content button{
    font-size: 14px;
float: right;
padding: 2px;
padding-left: 5px;
padding-right: 5px;
border: 2px solid white;
background-color: rgb(48, 70, 66);
color: white;

}

.story-img{
   
    flex-basis: 40%;
    min-width: 200px;
    overflow: hidden;
    aspect-ratio:1/0.8 ;
    display: flex;
    justify-content: center;
    align-items: center;
   
     /* border: 4px solid black; */
}

.story-img img{
  /* width: 100%; */
  height: 100%;
  transition:transform 500ms ease;
 
  
}
.story-img img:hover{
    transform: scale(1.2);
}
.ImpactStoryPagination-container{
    background-color: rgb(254, 254, 254);
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 900px){
.story{
    display: flex;
    flex-direction: column;
    height:auto;
}
.story-content{
    width: 100%;
}
.story-img{
    width: 100%;
   /* border: 10px solid black; */
  /* height: fit-content; */
  /* overflow: hidden; */
    flex-basis: auto;
    /* background-color: aqua; */
}
.story-img img{
height: 100%;

}
}
