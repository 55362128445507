
.initiativeHeader{
    width: 100%;
    position: relative;
    height: 25vw;
    max-height: 250px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    background-color: rgb(218, 230, 232);
    /* padding-bottom: 30px; */
    /* padding-top: 20px !important; */
}
.initiativeHeader-img{
    position: absolute;
    width: 100%;
   height: 110%;
}
.initiativeHeader-heading{
    z-index: 10;
    color: white;
   padding-top: 20px;
}
.initiativeHeader-heading h2{
    font-weight: 700;
    font-size: clamp(30px,5vw,53px);
    text-shadow: 2px 2px 10px black;
}
.initiativeContent{
    width: 100%;
    
    margin-top: 30px;
    margin-bottom: 30px;
    /* border: 2px solid red; */
    padding-left:  clamp(2px,5%,200px);
    /* border: 2px solid red; */
}
.initiativeContent-links{

    /* border: 2px solid black; */
     display: flex;
     flex-direction: column;
     /* margin: 20px; */
    /* background-color: rgb(237, 241, 240); */
    /* padding: 20px; */
    max-width: 900px;

}
.initiativeContent-links-single{
    background-color: rgb(237, 241, 240);
 /* min-width: 250px; */
margin-top: 10px;
display: flex;
flex-direction: column;
padding: 20px;
/* border: 2px solid red; */
}
.initiativeContent-links-single button{
    background-color: rgb(129, 2, 63);
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 10px 10px;
    align-self: center;
    width: 200px;
}
.initiativeContent-links-single a{
    color: grey;
    text-decoration: none;
}


/*-report content-*/

.reportContent{
    width: 100%;
   
    margin-top: 30px;
    margin-bottom: 30px;

    padding-left:  clamp(20px,10%,200px);;
}
.reportContent-links{
    border: 2px solid black;
display: inline-block;
    background-color: rgb(237, 241, 240);
    padding: 20px;
    
}
.reportContent-links-single{
    /* min-width: 250px; */
margin-top: 10px;
}
.reportContent-links-single a{
    color: grey;
    text-decoration: none;
}

.initiative-AddButton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.initiative-AddButton button{
    background-color: rgb(2, 2, 129);
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 0px 10px;
}

.modal-body-addInitiative form{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }
  .modal-body-addInitiative form input{
      margin-top: 10px;
      width: 100%;
      max-width: 700px;
  }
  .modal-body-addInitiative form textarea{
      margin-top: 10px;
      width: 100%;
      height: 100px;
      max-width: 700px;
  }
  .modal-body-addInitiative form button{
      width: 200px;
      margin: auto;
  }

  .modal-body-addReport form{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }
  .modal-body-addReport form input{
      margin-top: 10px;
      width: 100%;
      max-width: 700px;
  }
  .modal-body-addReport form textarea{
      margin-top: 10px;
      width: 100%;
      height: 100px;
      max-width: 700px;
  }
  .modal-body-addReport form button{
      width: 200px;
      margin: auto;
  }
  .initiativeContent-links-single img{
    width: 100%;
    max-width: 600px;
  }
  .disasterRelief{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* max-width: 700px; */
    /* height: 600px; */
    background-color: rgb(243, 245, 241);
    padding-left: 10%;
    padding-right: 10%;
  }
  .disasterRelief img{
    width: 100%;
max-width: 700px;
  }
  .disasterRelief h3{
    font-weight: 700;
    color: gray;
  }
  .disasterRelief  div{
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
  }

