.Events-HomePage{
    background-color: rgb(239, 244, 242);
    
    padding-top: 30px;
    padding-bottom: 30px;
display: flex;
flex-direction: column;
/* background-color: rgb(169, 124, 65); */
/* align-items: center; */
}
/* .Events-HomePage h1{
    display: block;
  
    text-align: center;
    margin-top: 5vh;
    font-size: clamp(20px,100vw,60px);
    color: rgb(69, 50, 198);

} */
/* .slider{
    display: flex;
    justify-content: space-between;
} */
.slideCard{
    /* width: 30vw !important; */
    height: 450px;
 
    margin-left: 2vw;
    overflow: hidden;
    /* border: 2px solid red; */
 
}
.Events-HomePage-heading {
display: flex;
justify-content: center;
}
.Events-HomePage-heading h3{
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
   font-size: 30PX;
/* margin: 0;
padding: 0;
font-size: clamp(18px,6vw,45px);
background-color: rgb(3, 110, 65);
color: rgb(96, 175, 142);
font-weight: 700;
border-radius: 15px;
padding: 2px 15px;
border :5px solid rgb(255, 255, 255) */
}
.Events-HomePage button{
    /* float: right; */
    margin: 10px;
    /* display: inline-block; */
    max-width: 200px;
    align-self: center;
    color: rgb(255, 255, 255);
    font-weight: 600;
border: none;
    background-color:rgb(128, 47, 47);
    padding: 5px 10px;
    text-align: center;
}
