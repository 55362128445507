.awardHeader{
    width: 100%;
    height: clamp(170px,60vw,500px);
    /* overflow: hidden; */
    position: relative;
    /* border: 2px solid rgb(4, 179, 19); */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.awardHeader{
    height: clamp(300px,80vw,720px);
    /* background-color: aqua; */
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.awardHeader-content{
    z-index: 1000;
}
.awardHeader-content h1{
    font-size: clamp(30px,10vw,70px);
    text-shadow: 2px 2px black;
    padding: 0;
    margin: 0;
    text-align: center;
    color: white;
}

.Cover-AwardPage{
    position: absolute;
    height: 100%;
    width: 100%;
}
.awardHeader-CoverpicContainer{
    position: absolute;
    width: 100%;
    height: clamp(170px,60vw,500px);
    overflow: hidden;
    /* border: 6px solid red; */
}
.awardHeader-CoverpicContainer img{
    position: absolute;
    /* height: 100%; */
    width: 100%;
}
.awardHeader-headingContainer{
z-index: 10;
}
.awardHeader-headingContainer h2{
    font-size: clamp(25px,6vw,60px);
    color: white;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-shadow: 2px 2px 10px black;
}






.modal-body-addAward form{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }
  .modal-body-addAward form input{
      margin-top: 10px;
      width: 100%;
      max-width: 700px;
  }
  .modal-body-addAward form textarea{
      margin-top: 10px;
      width: 100%;
      height: 100px;
      max-width: 700px;
  }
  .modal-body-addAward form button{
      width: 200px;
      margin: auto;
  }
.addAwardButton{
    width: 100%;
    margin: 20px 0px;
    /* background-color: antiquewhite; */
    display: flex;
    justify-content: center;
}
.addAwardButton button{
    border: none;
    background-color: rgb(4, 4, 110);
    color: white;
    padding: 5px 10px;
}

  .awardContent{
   /* border: 5px solid rgb(114, 111, 111); */
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }

  .awardContent-eventContainer-singleEvent{
    width: 90%;
    margin: auto;
    margin: 30px 10px;
    max-width: 320px;
    background-color: rgb(255, 255, 255);
    height: auto;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 500ms ease;
    border-radius: 10px;
    overflow: hidden;
  }
  .awardContent-eventContainer-singleEvent:hover{
    box-shadow: 2px 2px 10px black;
  }
  .awardContent-eventContainer-singleEvent h4{
margin: 10px;
text-transform: uppercase;
font-weight: 700;
font-size: 20px;

  }
  .awardContent-eventContainer-singleEvent img{
    height: 75vw;
   max-height: 280px;
    align-self: center;
    max-width: 100%;
}
.awardContent-eventContainer-singleEvent p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-align: justify;
    margin: 10px;
     -webkit-box-orient: vertical;
    overflow: hidden;
    color: rgb(0, 0, 0);
    font-size: 18px;
}

.awardContent-eventContainer-singleEvent-footer{
    width:100%;
    /* background-color: blueviolet; */
    border-top: 1px solid rgb(198, 3, 3);
    padding: 10px;
    height: auto;
    align-self: flex-end;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.awardContent-eventContainer-singleEvent-footer a{
    text-decoration: none;
    color: blue;
    font-size: 20px;
}
.awardContent-eventContainer-singleEvent-footer button{
    border: none;
    color: white;
    background-color: rgb(208, 18, 18);
    padding: 5px 10px;
}

  
  
  