.HomeStory{
    width: 100%;
    background-color: rgb(254, 254, 254);
    display: flex;
transition: all 300ms ease;

}
.HomeStory-left{
    flex-basis: 35%;
    /* width: 100%; */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
    background-color: rgb(219, 45, 129);
    transition: all 300ms ease;
    padding: 2%;
}
.HomeStory-left h4{
    font-size: 30px;
    font-weight: 600;
    color: rgb(120, 10, 63);
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.HomeStory-left p{
    color: white;
    margin: 0;
    text-align: center;
  
}
.HomeStory-slideShow{
    overflow: hidden;
    flex-basis: 65%;
    /* width: 100%; */
    background-color: rgb(255, 255, 255);
    padding: 5px;
}
.HomeStorysliderSingle{

    width: 100%;
    position: relative;
 /* padding-bottom: 100px; */
    /* background-color: rgb(106, 113, 165); */
}
.HomeStorysliderSingle-img{
    /* border: 2px solid black; */
    border-radius: 30px;
    width: 70%;
    margin: auto;
    max-width: 550px;
    aspect-ratio: 1/0.7;
    transition: all 300ms ease;
    overflow: hidden;
}
.HomeStorysliderSingle-img:hover{
    
    box-shadow: 2px 2px 10px black;

}
.HomeStorysliderSingle-img img{
    width: 100%;
    height: 100%;
}
.HomeStorysliderSingle-content{
    /* background-color: brown; */
   width: 100%;
   margin: auto;
   max-width: 500px;
   margin-top: 30px;
   padding-bottom: 50px;
   /* margin-bottom: 50px; */
   /* background-color: aquamarine; */
}
.HomeStorysliderSingle-content h4{
    color: rgb(120, 10, 63);
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  
     -webkit-box-orient: vertical;
    overflow: hidden;
}
.HomeStorysliderSingle-content h6{
    color: rgb(120, 10, 63);
    display: -webkit-box;
    -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
    overflow: hidden;
}
.HomeStorysliderSingle-content p{
    color: rgb(76, 75, 76);
    font-size: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
    overflow: hidden;
}
.HomeStorysliderSingle button{

width: 200px;
background-color: rgb(120, 10, 63);
padding :5px 0px;
color: white;
border: none;
/* margin: auto;
float:right;
margin: 20px; */
position: absolute;
left:50%;
bottom: 0;
transform: translate(-50%,-50%);
}
/* HomeStorysliderSingle-readmore */
@media screen and (max-width: 900px){
    .HomeStory{
        display: flex;
        flex-direction: column;
      
       
    }
}
@media screen and (min-width: 900px){
    .HomeStory{
     
        height: 58vw;
        max-height: 640px;
        overflow: hidden;
       
    }
}
