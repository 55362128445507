.careerHeader{
    width: 100%;
    height: clamp(300px,70vw,650px);
    position: relative;
    display: flex;
    justify-content: flex-end;
    
    align-items: center;
    overflow: hidden;
}
.careerHeader-img{
    position: absolute;
    width: 100%;
    height: clamp(300px,70vw,650px);
}
.careerHeader-heading{
   
    z-index: 10;
    margin-top: 2vw;
    margin-right: calc( 15% - 45px );
    color: white;
}
.careerHeader-heading h2{
    font-size: clamp(25px,6vw,45px);
    font-weight: 700;
    text-shadow: 2px 2px 10px black;
}
.openApplication{
    background-color: rgb(228, 223, 223);
    margin: auto;
    margin-top: 30px;
    width: 90%;
    max-width: 1200px;
    padding: 20px;

}
.openApplication h3{
    color: rgb(125, 128, 128);
}
.openApplication button{
    border: none;
    /* border: 1px solid black; */
    background-color: rgb(9, 114, 146);
    padding:5px 10px;
    border-radius: 5px;
    color: rgb(252, 254, 255);
transition: all 500ms ease;
}
.openApplication button:hover{
    box-shadow: 2px 2px 10px grey;
}
.openApplicationModal{
    background-color: rgba(2, 2, 2, 0.918);
    width: 100%;
    min-height: 100vh;
    z-index: 10000000;
    position: fixed;
    top: 0;
    left: 0;
     display: flex;
     justify-content: center;
     align-items: center;
}
.openApplicationModal form{
    width: 90%;
    border-radius: 20px;
    background-color: rgb(233, 235, 235);
    padding: 20px;
    max-width: 800px;
}
.openApplicationModal form input{
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
.openApplicationModal form button{
    background-color: rgb(88, 88, 239);
    color: white;
    padding:5px 10px;
    border: none;
}
.openApplicationModal form button:nth-child(1){
    margin-bottom: 10px;
    float: right;
}
.vacancy{
    background-color: rgb(228, 223, 223);
    margin: auto;
    margin-top: 30px;
    width: 90%;
    max-width: 1200px;
    padding: 20px;
}
@media screen and (max-width: 450px) {
    
    .careerHeader{
        justify-content: center;
    }
    .careerHeader-heading{
       margin-right: 0;
    }
  }

